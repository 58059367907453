import React, { Component,useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Header from '../common/layout/header';
import Footer from '../common/layout/footer'; 
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar'; 
import moment from 'moment';
import CommonAlert from '../common/Commonalert';
import { http } from '../helper';
import ShowAlert from '../common/Showalert';
const Freecodeadd =()=>
{

  const [Freecode,setFreeCode] = useState([]);
  const [Freecodelist,setFreeCodeList] = useState([]);
  const [errmessage, setErrMessage] = useState({});
  const [Freecodestatus,setFreeCodeStatus] = useState('A');
  const [alertPopup,setAlertPopup] = useState(false);
  const [alertMessage,setAlertMessage] = useState([]);
  const [alerterrorPopup,setAlertErrorPopup] = useState(false);
  const [alerterrMessage,setAlerterrMessage] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(["pizza", "pasta", "burrito"]);
  const [enableAddToCart, setEnableAddToCart] = useState([]);
  const [maxProductsAllowed, setMaxProductsAllowed] = useState(0);

  if(!sessionStorage.getItem("isLoggedIn")){
    window.location.href = '/';
}
if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_foodsolutions'))
    {
      window.location.href = '/unauthorized';
    }
const routeChange = (e) =>{ 
    e.preventDefault();
    window.location.href='/freesample/freecode';
}

useEffect(()=>
{
    getFreecodelist();

},[])


const getFreecodelist=()=> {

    let URL = BaseUrl + "admin/freecodelist";
        http.get(URL).then((response) => {
            setFreeCodeList(response.data.data);
           
        }).catch((err) => {
            console.log(err);
        })
    }

  const submitButton = (e) => {

    e.preventDefault();
    const isValid = formvalidation();

    if(isValid){
        let URL = BaseUrl +"admin/addfreecode";
        const payload = {
            Code: Freecode.trim(),
            //Freecodestatus : Freecodestatus,
            selectedCategories:selectedCategories,
            enableAddToCart:enableAddToCart,
            NumberofProductsallowed: parseInt(maxProductsAllowed)
            };

            const isFound = Freecodelist.some(element => {
                if (element.Code === Freecode.trim()) {
                  return true;
                }
            
                return false;
              });
            if(isFound)
            {
                setAlertErrorPopup(true);
                setAlerterrMessage('This Code has already been added'); 
    
            }
            else
            {
 
                    var loginDetails = {};
                    if (sessionStorage.getItem('authData')) {
                        loginDetails = JSON.parse(sessionStorage.getItem('authData'));
                    }
                
                    http.post(URL,payload).then((response)=>{
            
                        let responseData = response.data.data;
                        if (response.data.success === true) {
                            
                            setAlertPopup(true);
                            setAlertMessage('Free Code has been added successfully'); 
                        }
            
                    }).catch((error)=>{
                        console.log(error)
                
                    });
            }

    }
     
}

const accObjectiveoptions = Array.from({ length: 100 }, (_, i) => i)

const formvalidation = () =>
{
    
    const errmessage = {};
    let isValid = true;
    
    if(Freecode == '' )
    {
        errmessage.freecodeerr = "Code is Required";
        isValid = false;
    }
    else if(Freecode.length < 5 )
    {
        errmessage.freecodeerr = "Incorrect Account Code. Please try again.";
        isValid = false; 
    }
    
   
    if (selectedCategories.length === 0) {
        errmessage.categoryErr = "At least one category must be selected.";
        isValid = false;
    }
    setErrMessage(errmessage);
    return isValid;
}

   const handleChange = (e) => {

       
        var re = /^\S/;
        if(e.target.name == 'Freecode')
        {
            if (e.target.value === '' || re.test(e.target.value))
            setFreeCode(e.target.value);
            setErrMessage('');
        }
        if(e.target.name == 'Freecodestatus')
        {
            setFreeCodeStatus(e.target.value);
        }
};
const handleCategoryChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((item) => item !== category)
        : [...prev, category]
    );
  };

  const handleEnableAddToCart = (category) => {
    setEnableAddToCart((prev) =>
        prev.includes(category) ? prev.filter((c) => c !== category) : [...prev, category]
    );
};

  return (
    <>
    { alerterrorPopup &&  ( <ShowAlert title='Alert' message={alerterrMessage}/> )}
    {alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
        <div className="dashboard-block">
            
                <Sidebar />
           
            <div className="dash-left">
                <Header />
                <section className="cart-block">
                <div className="container">
                    <h1 className="checkout-title vi">Add Free Code</h1> 

                    <div className="right-table-outer-wrap"> 
                     <div className='myact-wrap'>
                        <div className='client-details'>
                            <div className='form-field holiday-add-fieldset form-container-xs'>
                                <form className='form-field freeproduct-fieldset holiday-add-fieldset' method='post' onSubmit={submitButton}>
                                    <div className='input-field col-1'>
                                        <label htmlFor='name'>Code</label>
                                        <input type="text" onChange={handleChange} value={Freecode} name='Freecode' placeholder='Enter Code' />
                                        {errmessage.freecodeerr && (<div className="error-msg">{errmessage.freecodeerr}</div>)} 
                                    </div>

                                    <div className="add-free-samp-disp">
                                    
                                        <h4>Category</h4>
                                        <div className="add-free-samp-chks">
                                        {["pizza", "pasta", "burrito"].map((category, index) => {
                                            let displayCategory = category === "pasta" ? "Pasta, Rice & Noodle Sauces" : category.charAt(0).toUpperCase() + category.slice(1);
                                           return (
                                    <div className="add-check-box-wrap" key={index}>
                                        <div className="check-box">
                                            <input
                                                type="checkbox"
                                                id={`freeCat${index}`}
                                                name={category}
                                                checked={selectedCategories.includes(category)}
                                                onChange={() => handleCategoryChange(category)}
                                            />
                                            <label htmlFor={`freeCat${index}`}>{displayCategory}</label>
                                        </div>
                                        {selectedCategories.includes(category) && (
                                            <div className='switch-button-holder'>
                                                <label>Enable Add to Cart</label>
                                                <div className="switch-button">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={enableAddToCart.includes(category) || false}
                                                        onChange={() => handleEnableAddToCart(category)}
                                                    />
                                                    <div className="knobs"><span></span></div>
                                                    <div className="layer"></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    
                                )})}
                                
                                        </div>
                                        {errmessage.categoryErr && <div className="error-msg">{errmessage.categoryErr}</div>}
                                    </div>
                                    <div className='input-field col-1'>
                                        <label htmlFor='name'>Maximum number of products allowed in the cart</label>
                                        <select className='cartVal' value={maxProductsAllowed} 
                                            onChange={(e) => setMaxProductsAllowed(e.target.value || "0")}>
                                            <option value="">Select value</option>
                                            {accObjectiveoptions.map((number) => (
                                            <option key={number} value={number}>
                                                {number}
                                            </option>
                                            ))}
                                        </select>
                                    </div>

                                 <div className='btnfield'>
                                 <button type='submit' className="submitbtn" >Submit</button>
                                  &nbsp;&nbsp;
                                  <button className="submitbtn" onClick={routeChange}>Cancel</button>
                                 </div>

            

                           </form>
                            </div>
                        </div>
                      </div>

                     </div>
                     </div>
                </section>
                <Footer />
               
            </div>
        </div>
		
    </>
);


}
export default Freecodeadd;

  

   