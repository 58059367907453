import React, { Component, createRef } from "react";
import Header from "../common/layout/header";
import Footer from "../common/layout/footer";
import { BaseUrl } from "../common/config";
import discountIcon from "../assets/images/discount-icon.png";
import { http } from "../helper";
import DataTable from "react-data-table-component";
import Sidebar from "../common/sidebar";
import moment from "moment";
import CommonAlert from "../common/Commonalert";
import Popup from "../common/popup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { dateformatwithuktime } from "../utilityfunctions";

class FreeOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      temporders: [],
      searchTextVal: "",
      loading: true,
      popupView: "",
      alertMessage: "",
      alertPopup: false,
      isElementVisible: false,
      datelabel: "Past 30 Days",
      datefilterlabel: "Past 30 Days",
      prevDatelabel: "",
      dateRange: "",
      datepickershow: false,
      datefrom: "",
      dateto: "",
      datefromLabel: "",
      datetoLabel: "",
      dateinvalid_error: false,
      datefill_error: false,
      searchstate: false,
      searchField: "OrderNumber",
      searchFieldtxt: "Order Number",
      notesPopup: false,
      note: "",
      currentOrder: [],
      orderlogs: [],
      noteError: false,
      isLoading: false,
      uploadMessage: "",
      showPopup: false,
      showstatusDialog:false,
      selectedStatus: "",
      errmessage: {
        orderst: ""
    },
    popupstatusLogView:""
     

    };
    this.searchFieldRef = createRef();
    this.searchInputRef = createRef();

    if (!sessionStorage.getItem("isLoggedIn")) window.location.href = "/";

    if (
      JSON.parse(sessionStorage.getItem("authData")) != "" &&
      !JSON.parse(
        sessionStorage.getItem("authData")
      ).adminRole.permissions.includes("manage_foodsolutions")
    ) {
      window.location.href = "/unauthorized";
    }
    this.dropdownRef = React.createRef();
    this.dropdownMobileRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.updateNoteLog = this.updateNoteLog.bind(this);
    this.hideNotePopup = this.hideNotePopup.bind(this);

    this.openFilter = this.openFilter.bind(this);
    // Initialize debounced search function
    //this.debouncedSearch = debounce(this.getAllOrdersforsearch, 500); // Adjust debounce delay as needed
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getAllOrders();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    clearInterval(this.interval);
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isElementVisible: false });
    }
  };
  openFilter = () => {
    this.setState((prevState) => ({
      isElementVisible: !prevState.isElementVisible,
    }));
  };
  getAllOrders() {

    var dateval = "";
    var dateval2 = "";

    if (this.state.datefrom && this.state.dateto) {
      dateval = this.state.datefrom ? new Date(this.state.datefrom) : "";
      dateval = format(dateval, "yyyy-MM-dd");
      dateval2 = new Date(this.state.dateto);
      dateval2 = format(dateval2, "yyyy-MM-dd");
    }

    let payload = {
      orderdays:
        this.state.dateRange && typeof (this.state.dateRange === "number")
          ? this.state.dateRange
          : this.state.dateRange && typeof (this.state.dateRange === "string")
            ? ""
            : 30,
      datefrom: dateval ? dateval : "",
      dateto: dateval2 ? dateval2 : "",
      from: "backend",
    };
    let URL = BaseUrl + "admin/freeorders";
    if (sessionStorage.getItem("authData")) {
      var loginemail = JSON.parse(sessionStorage.getItem("authData")).adminEmail;
    }
    http
      .post(URL, payload)
      .then((response) => {
        let responseData = [];
        
              responseData = response.data.data;
        //console.log(responseData.,'responseData');
        this.setState({
          orders: responseData,
          loading: false,
          temporders: responseData,
          alertPopup: false,
          alertMessage: "",
          refreshLoading: false,
          searchstate: false,
          searchField: "",
          searchFieldtxt: "Order Number",
          searchTextVal: ""

        });
        var element = document.querySelectorAll(".fa-refresh");
        element.forEach((row) => {
          row.classList.remove("fa-spin");
        });
      })
      .catch((err) => {
        var element = document.querySelectorAll(".fa-refresh");
        element.forEach((row) => {
          row.classList.remove("fa-spin");
        });
        this.setState({
          alertPopup: true,
          loading: false,
          alertMessage: `The Server is busy, please try after sometime`,
        });
      });
  }

 
  priceFormat(price) {
    if (price == null || isNaN(price)) {
      return "£0.00"; // Default value if price is null, undefined, or not a number
    }
    return price.toLocaleString("en-US", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 2,
    });
  }
 
  getOrderaccountdetails(orderrow) {
   let URL = BaseUrl + "admin/getfreeorderdetails/" + orderrow.OrderNumber;
    http.get(URL).then((response) => {
      let responseData = [];
      responseData = response.data.data[0];
      this.setState({ loading: false });
      this.viewOrderDetails(responseData);
    })
      .catch((err) => {
        this.setState({ loading: false });
        //reject(err); // Reject with error
      });

  }
  closePopup = () => {
    this.setState({ showPopup: false, uploadMessage: "" });
  };

  viewOrderDetails(order) {

    this.setState({ alertPopup: false, alertMessage: "" });
  
    let popupResult = (
      <Popup
        extClass="order-pop free-sample-details-pop"
        headMoreTxt={
          <>
            <div className="order-top">
              <div className="bottom order-flex">
                {/* Changed for getting account details of particular order  */}
                <div className="order_no_details">
                  <h3>Order No - {order.OrderNumber}</h3>
                  <p>
                    Order Placed on:{" "}
                    <span className="date">
                      {" "}
                      {moment(order.createdAt).format("DD/MM/YYYY hh:mm A")}
                    </span>{" "}
                  </p>
                 
                  <p>
                    Status:<span className="pay"> {order.OrderStatus}</span>
                  </p>
                  <p>
                    Code Used:<span className="pay"> {order.Code}</span>
                  </p>
                </div>

                <div className="order_account_details">
                  <p>
                  <span className="highlight">Company Name: </span> {" "}
                    <span className="date ord-act-data"> {order.companyName}</span>{" "}
                  </p>
                  <p>
                  <span className="highlight">Contact Person: </span> {" "}
                    <span className="date ord-act-data"> {order.userName}</span>{" "}
                  </p>
                  <p>
                  <span className="highlight">Email Address: </span> {" "}
                    <span className="pay ord-act-data"> {order.userEmail}</span>
                  </p>
                  <p>
                  <span className="highlight">Contact Number: </span> {" "}
                    <span className="date ord-act-data"> {order.userContactNo}</span>{" "}
                  </p>
                  <p>
                  <span className="highlight">Address: </span> {" "}
                    <span className="pay ord-act-data"> {order.userAddress}</span>
                  </p>

                </div>
               
              </div>
            </div>
          </>
        }
        closebtn={
          <>
            <div className="pop-header-top order-top">
              <div className="head">
                <h4>Free Sample Order Details - {order.OrderNumber}</h4>
              </div>
              <span
                onClick={() => this.setState({ popupView: "" })}
                className="close"
              ></span>
            </div>
          </>
        }
        bodyMoreTxt={
          <>
            <div className="modal-table">
              <h4 className="item-title">Ordered Items</h4>
              <div className="checkout-table">
                <div className="table_overflow">
                  <table>
                    <thead>
                      <tr>
                        <td>Product</td>
                        <td>Unit of Sale</td>
                       
                      </tr>
                    </thead>
                    <tbody>
                      
                      {order.items.map((item, index) => {
                        return (
                          (
                            <tr key={index}>
                              <td
                                data-title="Product"
                                className="data-product-hide"
                              >
                                {this.str_replace(item.Description)} (
                                {item.ProductNumber.includes("_")
                                  ? item.ProductNumber.substring(
                                    0,
                                    item.ProductNumber.indexOf("_")
                                  )
                                  : item.ProductNumber}
                                )
                               
                               
                              </td>
                              <td data-title="Unit of Sale">
                                {item.PricingUnit}
                              </td>
                             
                            </tr>
                          )
                        );
                      })}
                     
                    </tbody>
                    
                  </table>
                </div>
              </div>
            </div>
            <div className="ord-free-disclaimer">
              <p><i class="fa fa-exclamation-circle"></i>Our FREE product samples may be supplied in smaller pack sizes than the "Unit of Sale" listed online.</p>
            </div>
            {/* {order.OrderDiscount > 0 && (<span className="tot-ofr-applied">{order.OrderDiscount}% discount applied for this order!</span>)} */}
          </>
        }
      />
    );

    this.setState({ popupView: popupResult });
  }

  statusSubmit = () => {
    if (!this.state.selectedStatus) {
      this.setState(
          { errmessage: { ...this.state.errmessage, orderst: "Please select an order status." } },
          () => console.log("Error updated:", this.state.errmessage.orderst)
      );
      return;  // Stop further execution
  }
     var loginDetails = {};
      if (sessionStorage.getItem("authData")) {
        loginDetails = JSON.parse(sessionStorage.getItem("authData"));
      }
    let URL = BaseUrl + "admin/freeordersStatusChange/"+this.state.currentOrder.OrderNumber;
    let payload ={
      OrderStatus : this.state.selectedStatus,
      createdBy:loginDetails.adminName
    }
    http
    .put(URL, payload)
    .then((response) => {
      let responseData = [];
      
          responseData = response.data.data;
          //console.log(responseData,'responseData');
          this.setState({showstatusDialog:false});
          this.setState((prevState) => ({
            orders: prevState.orders.map(order =>
              order.OrderNumber === this.state.currentOrder.OrderNumber ? { ...order, OrderStatus: this.state.selectedStatus } : order
            ),
            selectedStatus: '',
          }));
          this.setState((prevState) => ({
            temporders: prevState.temporders.map(order =>
              order.OrderNumber === this.state.currentOrder.OrderNumber ? { ...order, OrderStatus: this.state.selectedStatus } : order
            ),
           
          }));
    })
    .catch((err) => {
      console.log("Error:", err); // Log the error

    });
};

  ChangeOrderstatus = (order)=>{
    this.setState({showstatusDialog:true, currentOrder: order});
  }
  
  str_replace(product_name) {
    if (product_name !== "") {
      product_name = product_name.replaceAll("amp;", "&");
      product_name = product_name.replaceAll("apos;'", "'' ");
      product_name = product_name.replaceAll("quot;'", "'' ");

      return product_name;
    } else {
      return product_name;
    }
  }

  getOrderNotes(OrderNote) {
    var note_test = OrderNote.replaceAll(" PIXHALLCSL", "");
    note_test = note_test.replaceAll("amp;", "&");
    note_test = note_test.replaceAll("apos;", "'' ");
    note_test = note_test.replaceAll("apos;'", "'' ");
    note_test = note_test.replaceAll("quot;'", "'' ");
    note_test = note_test.replaceAll("quot;", "'' ");
    note_test = note_test.replaceAll("'' '", "'' ");
    return note_test;
  }

  handleReset = () => {
    this.setState({
      orders: this.state.temporders,
      searchstate: false,
      searchField: "",
      searchFieldtxt: "Order Number",
      searchTextVal: "",
    });
    if (this.searchFieldRef.current) {
      this.searchFieldRef.current.selectedIndex = 1;
    }
    if (this.searchInputRef.current) {
      this.searchInputRef.current.value = "";
    }
  };

  // handleChange = async(value) => {
  //     this.setState({searchTextVal:value});
  //     //this.filterData(value);
  //   };

  handleChange = (e) => {
    const value = e.target.value;
    // const { value, selectedOptions, name } = e.target;
    // const isStatusDropdown = name === "statusDropdown"; 
    this.setState({ searchTextVal: value });
    // let statuses = [];
    // if (isStatusDropdown) {
    //   statuses = Array.from(selectedOptions, (option) => option.value.toLowerCase().trim());
    // }
    const lowercasedValue = value.toLowerCase().trim();
    const searchData = this.state.temporders.filter((item) => {
      if (Number.isInteger(Number(lowercasedValue))) {
        return item.OrderNumber.toLowerCase().includes(lowercasedValue);
      } 
      
    });
    this.setState({ orders: searchData, searchstate: true });

  };
  handleNoteChange = async (value) => {
    this.setState({ note: value.trim(), noteError: false });
  };

  filterData = () => {

    const value = this.state.searchTextVal;
    const lowercasedValue = value.toLowerCase().trim();
    const searchData = this.state.temporders.filter((item) => {
      if (Number.isInteger(Number(lowercasedValue))) {
        return item.OrderNumber.toLowerCase().includes(lowercasedValue);
      }
      else if (lowercasedValue !== "a" && lowercasedValue !== "k" && this.state.searchFieldtxt === 'Account Code') {
        return (item.accountCode.toLowerCase().includes(lowercasedValue));
      }
      else if (lowercasedValue !== "a" && lowercasedValue !== "k") {
        return (item.OrderStatus.toLowerCase().includes(lowercasedValue));
      } else if (lowercasedValue === "a" || lowercasedValue === "k") {
        return item.PaymentMethod.toLowerCase().includes(lowercasedValue);
      }
    });


    this.setState({ orders: searchData, alertPopup: false, searchstate: true });
   
  };


  applyOrderFilters() {

    let dateinvalid = false; //Added by K2b Sangeetha for date range error

    if (this.state.dateRange !== "") {
      this.setState({
        isElementVisible: false,
        loading: true,
        alertPopup: false,
        isElementVisibleMobile: false,
        dateinvalid_error: false,
        datefill_error: false,
      });

      var payload = {
        orderdays: this.state.dateRange,
        datefrom: "",
        dateto: "",
      };

      if (this.state.dateRange === "custom") {
        if (this.state.datefrom && this.state.dateto) {
          var dateval = new Date(this.state.datefrom);
          dateval = format(dateval, "yyyy-MM-dd");

          var dateval2 = new Date(this.state.dateto);
          dateval2 = format(dateval2, "yyyy-MM-dd");

          this.setState({
            datefromLabel: this.state.datefrom,
            datetoLabel: this.state.dateto,
            datefill_error: false,
          });

          payload = {
            orderdays: "",
            datefrom: dateval,
            dateto: dateval2,
          };
        } else {
          dateinvalid = true;
          this.setState({
            datefill_error: true,
            isElementVisible: true,
            loading: false,
          });
        }
      } else {
        this.setState({ datelabel: this.state.datefilterlabel });
        payload = {
          orderdays: this.state.dateRange,
          datefrom: "",
          dateto: "",
        };
        this.setState({
          datefromLabel: "",
          datetoLabel: "",
        });
      }
      if (!dateinvalid) {
        let URL = BaseUrl + "admin/freeorders";
        // Send the GET request with parameters in the 'params' property
        if (sessionStorage.getItem("authData")) {
          var loginemail = JSON.parse(sessionStorage.getItem("authData")).adminEmail;
        }
        http
          .post(URL, payload)
          .then((response) => {
            let responseData = [];
            
                responseData = response.data.data;
          
            this.setState({
              orders: responseData,
              temporders: responseData,
              loading: false,
            }, () => {
              // Call filterData after state update if searchstate is true
              if (this.state.searchstate) {
                this.filterData();
              }
            });

          })
          .catch((err) => {
            console.log("Error:", err); // Log the error

            this.setState({ loading: false });
          });
      }
    }
  }

  clearFilterOrderSearch() {

    this.setState(
      { isElementVisible: false, isElementVisibleMobile: false, dateRange: "3" },
      // () => {
      //   this.getAllOrders(); // Re-fetch data if needed
      // }
    );
  }
  handleFilterChange = (e) => {
    if (e.value !== "") {
      var valueDates = "";

      if (e.value == "1") {
        valueDates = 30;
        this.setState({
          datepickershow: false,
          datefilterlabel: "Past 30 Days",
        });
      } else if (e.value == "3") {
        valueDates = 30 * 3;
        this.setState({
          datepickershow: false,
          datefilterlabel: "Past 90 Days",
        });
      } else if (e.value == "6") {
        valueDates = 30 * 6;
        this.setState({
          datepickershow: false,
          datefilterlabel: "Past 180 Days",
        });
      } else if (e.value == "1Year") {
        valueDates = 365;
        this.setState({
          datepickershow: false,
          datefilterlabel: "Past 1 Year",
        });
      } else if (e.value == "custom") {
        valueDates = "custom";

        this.setState({ datepickershow: true, datefilterlabel: "" });
      }
      this.setState({ dateRange: valueDates, dateinvalid_error: false });
    }
  };
  DateChange(value, type) {
    if (type == "from") {
      this.setState({ datefrom: value, dateinvalid_error: false });
    } else if (type == "to") {
      var dateval = new Date(this.state.datefrom);
      dateval = format(dateval, "yyyy-MM-dd");

      var dateval2 = new Date(value);
      dateval2 = format(dateval2, "yyyy-MM-dd");

      if (dateval > dateval2) {
        this.setState({ dateto: "", dateinvalid_error: true });
      } else {
        this.setState({ dateto: value, dateinvalid_error: false });
      }
    }
  }
  /*added by k2bv */
  formatDate(dateString) {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  }
  /*added by k2bv */

  /*added by K2b*/
  handleSearchFieldChange = (event) => {
    //  this.setState({ searchField: event.target.value,searchFieldtxt:event.target.text});
    const selectedOption = this.searchFieldRef.current.selectedOptions[0];
    const textval = selectedOption ? selectedOption.innerText : "";

    //const textval = event.target.selectedOptions[0].innerText;
    this.setState({
      searchField: this.searchFieldRef.current.value,
      searchFieldtxt: textval,
      searchTextVal: "",
    });

    // Clear the input value
    if (this.searchInputRef.current) {
      this.searchInputRef.current.value = "";
    }
  };

  viewlogDetails(order_det, order) {
    let popupResult = (
      <Popup
        extClass="info-poup show"
        closebtn={
          <>
            <span
              onClick={() => this.setState({ popupstatusLogView: "" })}
              className="close"
            ></span>
          </>
        }
        headMoreTxt={
          <>
            <div className="order-top">
              <h4>
                <span>
                  Order Status Log - {order.OrderNumber}
                </span>
              </h4>
            </div>
          </>
        }
        bodyMoreTxt={
          <>
            <div className="bottom vendor-details prd-log">
              {order_det && order_det.length > 0 ? (
                order_det.map((prd_item) => {
                  
                 let createdby = prd_item.createdBy ? (
                    <>
                      <span className="log-user">{prd_item.createdBy}</span>
                      <span className="log-line">|</span>
                    </>
                  ) : (
                    ""
                  );
                  return (
                    <div className="info-item">
                      <div className="info-innr">
                        <div className="flex-log-hdr">
                          <p>
                            <span className="labeltxt acn-by">
                              {prd_item.Status}
                            </span>
                          </p>
                          <div className="hdrlog-rit">
                            {createdby}
                            <span className="log-date">
                              {dateformatwithuktime(prd_item.createdAt)}
                            </span>{" "}
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-logs">No logs available</div>
              )}
            </div>
          </>
        }
      />
    );

    this.setState({ popupstatusLogView: popupResult });
  }


  renderSearchInput = () => {
    const { searchTextVal } = this.state;
  
    const searchField = this.searchFieldRef.current
      ? this.searchFieldRef.current.value
      : "";

    if (searchField === "PaymentMethod") {
      return (
        <div className="orders-dropdown">
          <select
            className="search-ord"
            ref={this.searchInputRef}
            onChange={this.handleChange}
          >
            <option value="">Select</option>
            <option value="K">Online Payment</option>
            <option value="A">Pay Later</option>
          </select>
        </div>
      );
    } else if (searchField === "OrderStatus") {
      return (
        <div className="orders-dropdown">
          <select

            className="search-ord"
            ref={this.searchInputRef}
            onChange={this.handleChange}
          >
            <option value="">Select</option>
            <option value="New Order">New Order</option>
            <option value="Acknowledged">Acknowledged</option>
            <option value="Invoiced">Invoiced</option>
            <option value="Part Desp.  Inv.">Part Desp. Inv.</option>
            <option value="Cancelled">Cancelled</option>
          </select>

          
        </div>
      );
    } else {
      return (
        <input
          type="text"
          ref={this.searchInputRef}
          onChange={this.handleChange}
          placeholder={`Search by ${this.state.searchFieldtxt}`}
        />
      );
    }
  };
  /*added by K2b */

  orderNotelog = (order) => {
    this.setState({ isLoading: true });
    var loginDetails = {};
    let responseData = [];
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    let URL = BaseUrl + "admin/orderlog/" + order.OrderNumber;
    // Start loading
    this.setState({ isLoading: true, notesPopup: true, currentOrder: order });

    http
      .get(URL)
      .then(async (response) => {
        responseData = response.data.data;

        this.setState({
          isLoading: false,
          orderlogs: responseData,
          notesPopup: true,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.error("Error getting status:", error);
      });
  };

  hideNotePopup = () => {
    this.setState({
      notesPopup: false,
      noteError: false,
      orderlogs: "",
      note: "",
    });
  };

  orderStatuslog = (order) => {
    this.setState({ isLoading: true });
    var loginDetails = {};
    let responseData = [];
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    let URL = BaseUrl + "admin/freeorderStatuslog/" + order.OrderNumber;
    // Start loading
    
    http
      .get(URL)
      .then(async (response) => {
        responseData = response.data.data;
        this.viewlogDetails(responseData,order);
       
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.error("Error getting status:", error);
      });
  };

  

  updateNoteLog = () => {
    if (this.state.note.trim() !== "") {
      var loginDetails = {};
      if (sessionStorage.getItem("authData")) {
        loginDetails = JSON.parse(sessionStorage.getItem("authData"));
      }

      let noteURL = BaseUrl + "admin/orderlog";
      const notepayload = {
        orderID: this.state.currentOrder.OrderNumber,
        noteText: this.state.note,
        createdBy: loginDetails.adminName,
      };
      this.setState({ isLoading: true });

      http
        .post(noteURL, notepayload)
        .then(async (response) => {
          this.setState({
            notesPopup: false,
            isLoading: false,
            showPopup: true,
            uploadMessage: "Order Note submitted successfully!",
          });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.error("Error submitting note:", error);
        });
    } else {
      this.setState({ noteError: true });
    }
  };

  render() {
    
   
    const columns = [
      {
        name: "Order #",
        selector: (row) => row.OrderNumber,
        sortable: true,
      },
      {
        name: "Name",
        selector: (row) => row.userName,
        sortable: false,
      },
      {
        name: "Email Address",
        selector: (row) => row.userEmail,
        sortable: false,
      },
      {
        name: "Ordered Date",
        selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
        sortable: false,
      },
     
      {
        name: "Status",
        selector: (row) => row.OrderStatus,
        sortable: true,
      },

      {
        name: "Actions",
        selector: (row, index) => (
          <div className="order-action-tbl">
            <a
              className="title-tooltip title-up button"
              data-title="View Details"
              style={{ color: "white" }}
              onClick={() => this.getOrderaccountdetails(row)}
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </a>
            <a
              className="title-tooltip title-up button"
              data-title="Change Status"
              style={{ color: "white" }}
              onClick={() => this.ChangeOrderstatus(row)}
            >
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </a>
            <a
              className="button title-tooltip title-up"
              data-title="Order Note Log"
              onClick={() => this.orderNotelog(row)}
            >
              <i className="fa fa-history"></i>
            </a>

            <a
              className="button title-tooltip title-up"
              data-title="Status Log"
              onClick={() => this.orderStatuslog(row)}
            >
              <i className="fa fa-random"></i>
            </a>
           
          </div>
        ),
        sortable: false,
      },
    ];

    const customStyles = {
      rows: {
        style: {
          minHeight: "25px",
        },
      },
      headCells: {
        style: {
          padding: "8px", // override the cell padding for head cells
        },
      },
      cells: {
        style: {
          padding: "8px", // override the cell padding for data cells
        },
      },
    };

    const paginationComponentOptions = {
      rowsPerPageText: "",
      rangeSeparatorText: "",
      selectAllRowsItem: true,
      selectAllRowsItemText: "",
      noRowsPerPage: "",
    };
    const rangeOptions = [
      { value: "1", label: "Past 30 Days" },
      { value: "3", label: "Past 90 Days" },
      { value: "6", label: "Past 180 Days" },
      { value: "1Year", label: "Past 1 Year" },
      /*{ value: "Viewall", label: "View All" }*/
      { value: "custom", label: "Custom" },
    ];

    var datelabel = "";
    var dateValue = "";
    if (this.state.dateRange === "1") {
      datelabel = "Past 30 Days";
      dateValue = "1";
    } else if (this.state.dateRange === "3") {
      datelabel = "Past 90 Days";
      dateValue = "3";
    } else if (this.state.dateRange === "6") {
      datelabel = "Past 180 Days";
      dateValue = "6";
    } else if (this.state.dateRange === "365") {
      datelabel = "Past 1 Year";
      dateValue = "1Year";
    } else if (this.state.dateRange === "custom") {
      datelabel = "Custom";
      dateValue = "custom";
    } else if (this.state.dateRange === "") {
      datelabel = "Past 30 Days";
      dateValue = "1";
    }

    const nodatacomponent =
      this.state.searchstate === false ? (
        this.state.datefromLabel && this.state.datetoLabel ? (
          <div className="no-records-found">
            Sorry, there are no free sample order records found from {this.formatDate(this.state.datefromLabel)} to {this.formatDate(this.state.datetoLabel)}. Please consider using more filter option to refine your search.
          </div>
        ) : (
          <div className="no-records-found">
            Sorry, there are no free sample order records found for {this.state.datelabel}.
            Please consider using more filter option to refine your search.
          </div>
        )
      ) : (
        <div className="no-records-found">
          Sorry, no free sample orders were matching your keyword, please try again with a
          different search term.
        </div>
      );

    return (
      <>
        {this.state.alertPopup === true && (
          <CommonAlert title="Alert" message={this.state.alertMessage} />
        )}
        <section className="dashboard-block">
          <Sidebar />

          <div className="dash-left">
            <Header />

            <div className="cart-block">
              <div className="container">
                <h1 className="checkout-title">Free Sample Orders</h1>
                <div className="right-table-outer-wrap">
                  <div className="table-filter search-orders orders-search-bx">
                    <div className="orders-drop-left">
                      {/* <div className="orders-dropdown">
                        <select
                          className="handle-srch"
                          ref={this.searchFieldRef}
                          onChange={this.handleSearchFieldChange}
                        >
                          <option value="" disabled>
                            Search by Field
                          </option>
                          <option value="OrderNumber">Order Number</option>
                          
                        </select>
                      </div> */}
                      <div className="table-search-reset">
                        <div className="table-search">
                          {/* {this.renderSearchInput()} */}
                          <input
                            type="text"
                            ref={this.searchInputRef}
                            onChange={this.handleChange}
                            placeholder={`Search by ${this.state.searchFieldtxt}`}
                          />
                          {/* <input type="text" value={this.state.searchTextVal} onChange={this.handleChange} placeholder={`Search by ${this.state.searchFieldtxt}`} /> */}
                        </div>

                        <button
                          className="submitbtn button search-ord-btn"
                          onClick={
                            this.filterData
                          }
                        >
                          <i className="fa fa-search"></i>
                        </button>
                        <button
                          className="resetbtn"
                          type="reset"
                          onClick={this.handleReset}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                    <div className="select-drop" ref={this.dropdownRef}>
                      <a
                        className={
                          this.state.isElementVisible == true
                            ? "filter-btn button active"
                            : "filter-btn button"
                        }
                        onClick={this.openFilter}
                      >
                        More Filter{" "}
                        <span className="filter-icon">
                          <i className="fa fa-sliders" aria-hidden="true"></i>
                        </span>
                      </a>
                      <div
                        className={
                          this.state.isElementVisible == true
                            ? "filter-bx show"
                            : "filter-bx"
                        }
                      >
                        <div className="filter-date-range">
                          <div className="fltr-dt-wrap">
                            <label>Date Range (Ordered Date):</label>
                            <Select
                              defaultValue={{
                                value: dateValue,
                                label: datelabel,
                              }}
                              options={rangeOptions}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 6,
                                height: 40,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#e0dedb",
                                  primary: "#ec2f45",
                                },
                              })}
                              onChange={this.handleFilterChange}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            this.state.datepickershow == true
                              ? "fltr-dt-range show"
                              : "fltr-dt-range"
                          }
                        >
                          <div className="fltr--date">
                            <span>From</span>
                            <DatePicker
                              selected={this.state.datefrom}
                              placeholderText="From date"
                              showDisabledMonthNavigation
                              dateFormat="dd/MM/yyyy"
                              disablePast={true}
                              maxDate={new Date()}
                              onChange={(date) => this.DateChange(date, "from")}
                            />
                            <span className="date-icon">
                              <i
                                className="fa fa-calendar"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <div className="fltr--date">
                            <span>To</span>
                            <DatePicker
                              selected={this.state.dateto}
                              placeholderText="To date"
                              showDisabledMonthNavigation
                              dateFormat="dd/MM/yyyy"
                              disablePast={true}
                              maxDate={new Date()}
                              onChange={(date) => this.DateChange(date, "to")}
                            />
                            <span className="date-icon">
                              <i
                                className="fa fa-calendar"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>
                        {this.state.dateinvalid_error === true && (
                          <span className="color-red">
                            Invalid Start & End date!
                          </span>
                        )}
                        {this.state.datefill_error === true && (
                          <span className="color-red">
                            Please fill the from and to date!
                          </span>
                        )}
                        <div className="filter-btns">
                          <button
                            type="button"
                            className="button clear-btn"
                            onClick={this.clearFilterOrderSearch.bind(this)}
                          >
                            Clear
                          </button>
                          <button
                            type="button"
                            className="button apply-btn"
                            onClick={this.applyOrderFilters.bind(this)}
                          >
                            Apply{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.orders.length > 0 &&
                    this.state.loading === false && (
                      <p className="admin-order account-total-records">
                        Total Records:{" "}

                        {this.state.orders.length > 0
                          ? this.state.orders.length
                          : ""}
                       

                        {
                          this.state.datefromLabel && this.state.datetoLabel ? (
                            <span>
                              {" "}
                              (From {this.formatDate(
                                this.state.datefromLabel
                              )}{" "}
                              to {this.formatDate(this.state.datetoLabel)})
                            </span>
                          ) : //` (${ this.state.datelabel })`
                            this.state.datelabel ? (
                              ` (${this.state.datelabel})`
                            ) : (
                              ""
                            )
                        }

                        
                      </p>)}



                  <div className="checkout-table table_overflow free-sample-orders orders-table">
                    <DataTable
                      columns={columns}
                      //  noDataComponent="Sorry, no orders were matching your keyword, try a new search."
                      noDataComponent={nodatacomponent}
                      data={this.state.orders}
                      customStyles={customStyles}
                      pagination
                      paginationPerPage={25}
                      progressPending={this.state.loading}
                      paginationRowsPerPageOptions={[25, 50, 75, 100]}
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
          {this.state.popupView}
          {this.state.popupstatusLogView}
        </section>

        {/* Loader & Popup START */}
        <div className="common">
          {/* <Alert extractId="success" customContent="Cart Added Success" /> */}
        </div>
        {/* Loader & Poupp END */}

        {this.state.showstatusDialog && (
            <div className="custom-alert-pop paylater-popup">
              <div className="custom-alert-container">
                <div className="custom-alert-wrap">
                  <div className="custom-alert-header">
                    <h4>Order Status</h4>
                  </div>
                  <div className="custom-alert-body">
                   
                    <div className="form-field bottom vendor-details">
                        
                        <div className="input-field col-1">
                        <span className="labeltxt">Status</span>
                        <select name="orderstatuschange" 
                                onChange={(e) => this.setState({ 
                                  selectedStatus: e.target.value, 
                                  errmessage: { ...this.state.errmessage, orderst: "" } 
                              })}
                              defaultValue={this.state.selectedStatus || ""}
                            >
                              <option value = "" disabled>Select</option>
                                <option value="Acknowledged">Acknowledged</option>
                                <option value="Completed">Completed</option>
                                <option value="Cancelled">Cancelled</option>
                            </select>
                            {this.state.errmessage.orderst && (
                                <div className="error-msg">
                                    {this.state.errmessage.orderst}
                                </div>
                            )}
                        </div>
                        
                        <div className="btn-field btn-center"><button type="button"  onClick={() => this.statusSubmit()}>Submit</button>&nbsp;&nbsp;<button onClick={() => this.setState({ showstatusDialog: false,selectedStatus: "" })}>Cancel</button></div>          
                    </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
          )}


        {this.state.notesPopup && (
          <Popup
            extClass="info-poup show"
            closebtn={
              <>
                <span onClick={this.hideNotePopup} className="close"></span>
              </>
            }
            headMoreTxt={
              <>
                <div className="order-top">
                  <h4>
                    <span>
                      Notes for Order - {this.state.currentOrder.OrderNumber}
                    </span>
                  </h4>
                </div>
              </>
            }
            bodyMoreTxt={
              <>
                <div className="bottom vendor-details ordernotes-log">
                  <div className="order-notes">
                    <div className="input-field">
                      <label for="note-label">Enter your note</label>
                      <textarea
                        name="note"
                        onChange={(e) => this.handleNoteChange(e.target.value)}
                      ></textarea>
                      {this.state.noteError && (
                        <div className="err-msg">Please update note</div>
                      )}
                    </div>
                  </div>
                  <div className="alert-buttons btn-field btn-center">
                    <button
                      onClick={this.updateNoteLog}
                      className="popup-alert-btn button"
                    >
                      Submit
                    </button>
                    <button
                      onClick={this.hideNotePopup}
                      className="popup-alert-btn button"
                    >
                      Cancel
                    </button>
                  </div>
                  {this.state.isLoading ? (
                    <div className="loading-buffer"></div>
                  ) : (
                    <div className="order-notes-log">
                      {this.state.orderlogs &&
                        this.state.orderlogs.length > 0 &&
                        this.state.orderlogs.map((ord_item) => {
                          return (
                            <div className="info-item">
                              <div className="info-innr">
                                <p>
                                  <span className="labeltxt">Date: </span>
                                  {dateformatwithuktime(ord_item.createdAt)}
                                </p>
                                <p>
                                  <span className="labeltxt log-reason">
                                    Note:{" "}
                                  </span>
                                  {ord_item.noteText}
                                </p>
                                <p>
                                  <span className="labeltxt log-reason">
                                    Created by:{" "}
                                  </span>
                                  {ord_item.createdBy}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </>
            }
          />
        )}

        {this.state.showPopup && (
          <div className="custom-alert-pop paylater-popup">
            <div className="custom-alert-container">
              <div className="custom-alert-wrap">
                <div className="custom-alert-header">
                  <h4>Alert</h4>
                </div>
                <div className="custom-alert-body">
                  <p>{this.state.uploadMessage}</p>

                  <div className="alert-buttons">
                    <button
                      onClick={this.closePopup}
                      className="popup-alert-btn button"
                    >
                      ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        
      </>
    );
  }
}

export default FreeOrders;
