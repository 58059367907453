import React, { Component,useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
import { http } from '../helper';
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import CommonAlert from '../common/Commonalert';

const Freecodeedit =()=>
{
  
  const [Freecode,setFreeCode] = useState([]);
  const [prevFreecode,setPrevFreecode] = useState([]);
  const [freecodeList,setFreecodeList] = useState([]);
  const [errmessage, setErrMessage] = useState({});
  const [freecodeStatus,setFreecodeStatus] = useState([]);
 const [isLoading, setIsLoading] = useState(true); 
 const [alertPopup,setAlertPopup] = useState(false);
   const [alertMessage,setAlertMessage] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
     const [enableAddToCart, setEnableAddToCart] = useState([]);
     const [maxProductsAllowed, setMaxProductsAllowed] = useState(0);
   
  const params = useParams();
 

 if(!sessionStorage.getItem("isLoggedIn")){
    window.location.href = '/';
}
if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
    {
      window.location.href = '/unauthorized';
    }
const routeChange = (e) =>{ 
    e.preventDefault();
    window.location.href='/freesample/freecode';
}
useEffect(()=>
{
    let URL = BaseUrl + `admin/freecode/${params.id}`;
    http.get(URL).then((response) => {
     console.log(response.data.data);
        
        setFreeCode(response.data.data[0].Code);
        setPrevFreecode(response.data.data[0].Code);
        setSelectedCategories(response.data.data[0].selectedCategories);   
        setEnableAddToCart(response.data.data[0].enableAddToCart);
        setMaxProductsAllowed(response.data.data[0].NumberofProductsallowed)
        setIsLoading(false);
    }).catch((err) => {
        console.log(err);
        setIsLoading(false);
    });  

    getFreecodelist();

},[])

const getFreecodelist=()=> {

    let URL = BaseUrl + "admin/freecodelist";
    http.get(URL).then((response) => {
        setFreecodeList(response.data.data);
        
    }).catch((err) => {
        console.log(err);
    })
}


  const submitButton = (e) => {

    e.preventDefault();
    const isValid = formvalidation();

    if(isValid){

        if(prevFreecode != Freecode.trim())
            {
       
            var isFound = freecodeList.some(element => {
               if (element.Code === Freecode.trim()) {
                 return true;
               }
           
               return false;
             });
            }
            else{
       
               var isFound = false;
            }

            if(isFound)
                {
                    setAlertPopup(true);
                    setAlertMessage('This Code has already been added'); 
                  }
                  else
                  {
                            let URL = BaseUrl +`admin/editfreecode/${params.id}`;
                            const payload = {
                                    codeid:params.id,
                                    Code: Freecode.trim(),
                                    selectedCategories:selectedCategories,
                                    enableAddToCart:enableAddToCart,
                                    NumberofProductsallowed: parseInt(maxProductsAllowed)
                                };
                    
                                http.put(URL,payload).then((response)=>{   
                            
                                if (response.data.success === true) {
                                    setAlertPopup(true);
                                    setAlertMessage('Code has been updated successfully'); 
                                }
                    
                            }).catch((error)=>{
                    
                    
                            
                            console.log(error)
                        
                            });
                    }

    }
  
}

const formvalidation = () =>
{
    
    const errmessage = {};
    let isValid = true;
    
    if(Freecode == '' )
    {
        errmessage.freecodeerr = "Code is Required";
        isValid = false;
    }
    else if(Freecode.length < 5 )
    {
        errmessage.freecodeerr = "Incorrect Account Code. Please try again.";
        isValid = false; 
    }

    if (selectedCategories.length === 0) {
        errmessage.categoryErr = "At least one category must be selected.";
        isValid = false;
    }

    setErrMessage(errmessage);
    return isValid;
}

   const handleChange = (e) => {
        if(e.target.name == 'Freecode')
        {
            setFreeCode(e.target.value);
        }
        
};

const handleCategoryChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((item) => item !== category)
        : [...prev, category]
    );
  };

  const handleEnableAddToCart = (category) => {
    setEnableAddToCart((prev) =>
        prev.includes(category) ? prev.filter((c) => c !== category) : [...prev, category]
    );
};
const accObjectiveoptions = Array.from({ length: 100 }, (_, i) => i)
  return (
    <>
    {alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
        <div className="dashboard-block">
            
                <Sidebar />
           
            <div className="dash-left">
                <Header />
                <section className="cart-block">
                <div className="container">
                    <h1 className="checkout-title">Free Code Edit</h1>  

                    <div className="right-table-outer-wrap">
                    {isLoading  ? (
                            <div className='loading-buffer'></div>
                        ):(
                            <div className='myact-wrap'>
                            <div className='client-details'>
                                <div className='form-field holiday-add-fieldset form-container-xs'>
                                <form className='form-field freeproduct-fieldset holiday-add-fieldset' onSubmit={submitButton}>
                                    <div className='input-field col-1'>
                                        <label htmlFor='name'>Code</label>
                                        <input type="text" onChange={handleChange} value={Freecode} name='Freecode' />
                                        <div style={{color:"red"}}>{errmessage.freecodeerr}</div> 
                                    </div>
                                
                                    <div className="add-free-samp-disp">
                                    
                                    <h4>Category</h4>
                                    <div className="add-free-samp-chks">
                                    {["pizza", "pasta", "burrito"].map((category, index) => {
                                        let displayCategory = category === "pasta" ? "Pasta, Rice & Noodle Sauces" : category.charAt(0).toUpperCase() + category.slice(1);
                                       return (
                                <div className="add-check-box-wrap" key={index}>
                                    <div className="check-box">
                                        <input
                                            type="checkbox"
                                            id={`freeCat${index}`}
                                            name={category}
                                            checked={selectedCategories.includes(category)}
                                            onChange={() => handleCategoryChange(category)}
                                        />
                                        <label htmlFor={`freeCat${index}`}>{displayCategory}</label>
                                    </div>
                                    {selectedCategories.includes(category) && (
                                        <div className='switch-button-holder'>
                                            <label>Enable Add to Cart</label>
                                            <div className="switch-button">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox"
                                                    checked={enableAddToCart.includes(category) || false}
                                                    onChange={() => handleEnableAddToCart(category)}
                                                />
                                                <div className="knobs"><span></span></div>
                                                <div className="layer"></div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                
                            )})}
                            
                                    </div>
                                    {errmessage.categoryErr && <div className="error-msg">{errmessage.categoryErr}</div>}
                                </div>
                                <div className='input-field col-1'>
                                    <label htmlFor='name'>Maximum number of products allowed in the cart</label>
                                    <select className='cartVal' value={maxProductsAllowed} 
                                        onChange={(e) => setMaxProductsAllowed(e.target.value || "0")}>
                                        <option value="">Select value</option>
                                        {accObjectiveoptions.map((number) => (
                                        <option key={number} value={number}>
                                            {number}
                                        </option>
                                        ))}
                                    </select>
                                </div>

                                    <div className='btnfield'>
                                    <button type="submit" name="btnsubmit" onClick={submitButton}>Submit</button>
                                    &nbsp;&nbsp;&nbsp;
                                    <button type="button" onClick={routeChange}>Cancel</button>
                                    </div>

                             </form>
                            </div>
                            </div>
                            </div>
                        )}
                        
                     

                     </div>
                     </div>
                </section>

                <Footer />
            </div>
     
        </div>
    </>
);


}
export default Freecodeedit;

  

   