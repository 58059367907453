import React, { useEffect, useState } from "react";
import DataTable, { SortOrder } from "react-data-table-component";
import { http } from "../helper";
import { BaseUrl } from "../common/config";
import DatePicker from "react-datepicker";
import Popup from "../common/popup";
import { dateformatwithuktime } from "../utilityfunctions";

import { format } from "date-fns";
export const Abandonedcart = ({ }) => {
    const [popupView, setPopupView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({});
    const [cartItems, setCartItems] = useState([]);
    const columns = [
        {
            name: "Account Code",
            selector: (row) => row.accountCode,
            sortable: false,
        },
        {
            name: "Account Name",
            selector: (row) => row.accountinfo.accountName,
            sortable: false,
        },
        {
            name: "No of Items",
            selector: (row) => (
                <a
                    className="title-tooltip title-up button"
                    data-title="View Items"
                    style={{ color: "white" }}
                //     onClick={() => viewCartItems({ accountCode: row.accountCode, accountName: row.customerName, accountEmail: row.customerEmail,accountTelephone:row.customerTelephone })}
                // >
                    onClick={() => viewCartItems({accountinfo: row.accountinfo })}
                    >
                    {row.itemsCount}
                </a>
            ),
            sortable: true,
        },
    ];

    const priceFormat = (price) => {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };

    const customStyles = {
        rows: {
            style: {
                minHeight: "25px",
            },
        },
        headCells: {
            style: {
                padding: "8px", // override the cell padding for head cells
            },
        },
        cells: {
            style: {
                padding: "8px", // override the cell padding for data cells
            },
        },
    };

    const str_replace = (product_name) => {
        if (product_name !== "") {
            product_name = product_name.replaceAll("amp;", "&");
            product_name = product_name.replaceAll("apos;'", "'' ");
            product_name = product_name.replaceAll("quot;'", "'' ");

            return product_name;
        } else {
            return product_name;
        }
    };

    const viewCartItems = (customer) => {
        const addressFields = [
            customer.accountinfo.accountAddress1,
            customer.accountinfo.accountAddress2,
            customer.accountinfo.accountAddress3,
            customer.accountinfo.accountAddress4,
            customer.accountinfo.accountAddress5,
          ];
          customer.accountinfo.addressInfo = addressFields.filter((field) => field !== undefined && field !== "")
                        .join(", ")
        setCustomerDetails(customer.accountinfo);
        setLoading(true);
        setCartItems([]);
        setPopupView(true);
        let URL = BaseUrl + "admin/cart/" + customer.accountinfo.accountCode;
        http
            .get(URL)
            .then((response) => {
                setCartItems(response?.data?.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                //reject(err); // Reject with error
            });
    }

    const paginationComponentOptions = {
        rowsPerPageText: "",
        rangeSeparatorText: "",
        selectAllRowsItem: true,
        selectAllRowsItemText: "",
        noRowsPerPage: "",
    };

    const [filter, setFilter] = useState(0);
    const [filtertext, setFilterText] = useState("");
    const [abandonedcart, setAbandonedCart] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const excludeTestids = ['K2BTE003','K2BTE005','K2BTE001','K2BTE002','TESTA004','TESTA005','TESTA007','IEPIZ001'];
    useEffect(() => {
        if (filter === "custom" && (!fromDate || !toDate)) {
            // Don't make the API call if dates are missing for the "custom" filter
            return;
        }
        let payload = {};
        if (filter === "custom") {
            payload = {
                filter: filter,
                datefrom: format(new Date(fromDate), "yyyy-MM-dd"),
                dateto: format(new Date(toDate), "yyyy-MM-dd"),
            };
            setShowDatePicker(false);
        } else {
            payload = {
                filter: parseInt(filter),
                datefrom: "",
                dateto: "",
            };
        }
        let URL2 = BaseUrl + "admin/abandonedcart";
        http
            .post(URL2, payload)
            .then((response) => {
                setIsLoading(false);
                //console.log(response.data.data,'response');
                const responseData = response.data.data.filter(item => !excludeTestids.includes(item.accountCode));
                setAbandonedCart(responseData);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [filter, fromDate, toDate]);

    const changeFilterHandler = (e) => {
        setFilter(e.target.value);
        setFilterText(e.target.options[e.target.selectedIndex].text);
        if (e.target.value === "custom") {
            setShowDatePicker(true);
        } else {
            setShowDatePicker(false);
            setFromDate(null);
            setToDate(null);
            setFilter(e.target.value);
        }
    };
    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    };

    const DateChange = (value, type) => {
        if (type === "from") {
            setFromDate(value);

            if (toDate && value && value > toDate) {
                setToDate(null);
            }
        } else if (type === "to") {
            if (!fromDate || value >= fromDate) {
                setToDate(value);
            } else {
                // Handle invalid date selection
                //alert("The 'To' date cannot be earlier than the 'From' date.");
                setToDate(null);
            }
        }
    };

    // Calculate dynamic pagination options
    const calculatePaginationOptions = (totalRecords, interval) => {
        const options = [];
        for (let i = interval; i <= totalRecords; i += interval) {
            options.push(i);
        }
        return options;
    };

    const totalRecords = abandonedcart.length; // Get the total records count
    const paginationOptions = calculatePaginationOptions(totalRecords, 10); // Generate options with interval of 10

    let loadingbuffer = isLoading ? "loading-buffer" : "";
    return (
        <div
            className={`chart-container chart-abandd-cart ${loadingbuffer}`}
            style={{ verticalAlign: "top" }}
        >
            <div className="chart-Hdr">
                <div className="chart-Hdr-txt">
                    <h2>Abandoned Cart </h2>
                </div>
                <div
                    className="chart-fltr"
                    onMouseEnter={() => {
                        if (filter === "custom" && !showDatePicker) {
                            setShowDatePicker(true);
                        }
                    }}
                    onMouseLeave={() => {
                        if (filter === "custom" && showDatePicker) {
                            setShowDatePicker(false);
                        }
                    }}
                >
                    {
                        <select value={filter} onChange={(e) => changeFilterHandler(e)}>
                            <option value={0}>All</option>
                            <option value={30}>Past 30 Days</option>
                            <option value={90}>Past 90 Days</option>
                            <option value={180}>Past 180 Days</option>
                            <option value={365}>Past 1 Year</option>
                            <option value={"custom"}>Custom Date</option>
                        </select>
                    }
                    {showDatePicker && (
                        <div className="date-picker-container">
                            <label>From:</label>
                            <DatePicker
                                selected={fromDate}
                                onChange={(date) => DateChange(date, "from")}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select Date"
                            />
                            <label>To:</label>
                            <DatePicker
                                selected={toDate}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => DateChange(date, "to")}
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select Date"
                            />
                        </div>
                    )}
                </div>
                <div className="fltr-sub-val">
                    {abandonedcart.length > 0 && (
                        <span className="fltr-val">
                            Total Records: <strong>{abandonedcart.length}</strong> (
                            {filtertext != "" && filtertext != "Custom Date"
                                ? filtertext
                                : filtertext === "Custom Date" && fromDate && toDate
                                    ? formatDate(fromDate) + " - " + formatDate(toDate)
                                    : "All"}
                            )
                        </span>
                    )}
                </div>
            </div>
            <DataTable
                columns={columns}
                data={abandonedcart}
                customStyles={customStyles}
                noDataComponent={
                    <div className="no-records-found">
                        There are no records to display
                    </div>
                }
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 40, 60, 80, 100]}
                //paginationRowsPerPageOptions={paginationOptions}
                paginationComponentOptions={paginationComponentOptions}
            // paginationComponentOptions={{
            //     rowsPerPageText: '', // Hides "Rows per page"
            // }}
            />
            {popupView && <Popup
                extClass="order-pop abandoned-cart-details-pop"
                headMoreTxt={
                    <>
                        <div className="order-top">
                            <div className="bottom order-flex"><div className="order_account_details abandoned-cart-left">
                                <p>
                                    Name:{" "}
                                    <span className="date">{customerDetails.accountName}</span>{" "}
                                </p>
                                <p>
                                    Account Code:{" "}
                                    <span className="date">{customerDetails.accountCode}</span>{" "}
                                </p>
                                <p>
                                    Route Day(s):{" "}
                                    <span className="pay">{(customerDetails.accountDeliverableDays === 'All')?'Monday/Tuesday/Wednesday/Thursday/Friday':customerDetails.accountDeliverableDays}</span>
                                </p>
                            </div>
                            <div className="order_account_details">
                                    
                                    <p>
                                        Telephone Number:{" "}
                                        <span className="date">{customerDetails.accountTelephone}</span>{" "}
                                    </p>
                                    <p>
                                        Email Address:{" "}
                                        <span className="pay">{customerDetails.accountEmail}</span>
                                    </p>
                                    <p>
                                        Address:{" "}
                                        <span className="pay">{customerDetails.addressInfo}</span>
                                    </p>

                                   
                                </div>
                            </div></div>
                    </>
                }
                closebtn={
                    <>
                        <div className="pop-header-top order-top">
                            <div class="head"><h4>Abandoned Cart Details</h4></div>
                            <span onClick={() => setPopupView(false)} className="close"></span>
                        </div>
                    </>
                }
                bodyMoreTxt={
                    <>
                        <div className="modal-table">
                            <h4 className="item-title">Added Items</h4>
                            {loading && (
                                <div className="loading-overlay">
                                    <div className="loading-message">Loading...</div>
                                </div>
                            )}

                            {!loading && <div className="checkout-table">
                                <div className="table_overflow">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>Product</td>
                                                <td align="center">Unit of Sale</td>
                                                <td align="center">Item Added on</td>
                                                <td align="center">Quantity</td>
                                                <td align="center">Price</td>
                                                <td align="right">Total</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cartItems.length > 0 && cartItems.map((item, index) => {
                                                return (
                                                    item.qty > 0 && (
                                                        <tr key={index} className={item.itemPrice === 0 ? 'free-prod-bg' : ''}>
                                                           <td
                                                                data-title="Product"
                                                                className="data-product-hide"
                                                            >
                                                                {str_replace(item.productDescription)} (
                                                                {item.productNumber.includes("_")
                                                                    ? item.productNumber.substring(
                                                                        0,
                                                                        item.productNumber.indexOf("_")
                                                                    )
                                                                    : item.productNumber}
                                                                )
                                                            </td>
                                                            <td data-title="Unit of Sale" align="center">
                                                                {item.packageSize}
                                                            </td>
                                                            <td data-title="dateform" align="center">{dateformatwithuktime(item.createdAt)}</td>
                                                            <td data-title="Quantity" align="center">{item.qty}</td>
                                                            <td data-title="Price" align="center">
                                                                {priceFormat(item.itemPrice)}
                                                            </td>
                                                            <td data-title="Total" align="right">
                                                                {priceFormat(item.itemTotal)}
                                                            </td>
                                                        </tr>
                                                    )
                                                );
                                            })}
                                            {/* Added Special offer and product number like frontend by k2b Sangeetha   */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                    </>
                }
            />}
        </div>
    );
};
